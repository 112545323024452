/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─
/*─                                       SCROLLBAR
/*─
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/

* {
  scrollbar-width: 9px;
  scrollbar-color: #8e8e8e #d9d9d9;
}

::-webkit-scrollbar{
  height: 9px;
  width: 9px;
  background: #d9d9d9;
  border-radius: 20px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #d9d9d9;
  border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #8e8e8e;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:horizontal{
  background: #8e8e8e;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

::-webkit-scrollbar-thumb:horizontal:hover {
  background: #555;
}
