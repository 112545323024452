/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─
/*─                                       ANIMATIONS
/*─
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/

@keyframes enterIn {
  from {
    transform: translateX(-500px);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes enterInBottom {
  from {
    transform: translateY(50%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes animateIn {
  from {
    opacity: 0;
    transform: scale(0.6) translateY(-8px);
  }

  to {
    opacity: 1;
  }
}

@keyframes animateInHeight {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes animateOutHeight {
  from {
    transform: translateY(0) scale(1);
  }

  to {
    transform: translateY(100vh) scale(2);
  }
}

@keyframes animateTextEnter {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes animateBackgroundOpacityEnter {
  from {
    background: rgba(0, 0, 0, 0);
  }
  to {
    background: rgba(0, 0, 0, 0.4);
  }
}
