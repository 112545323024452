.LanguageSwitcher {
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  max-height: 40px;

  button {
    height: 100%;
    padding: 0 10px;
    border-radius: 0;
    border: solid #000;
    border-width: 2px 1px;
    background: rgba(255, 255, 255, 0.5);
    text-transform: uppercase;
    font-size: 1rem;

    &:first-child {
      border-radius: 10px 0 0 10px;
      border-width: 2px 1px 2px 2px;
    }

    &:last-child {
      border-radius: 0 10px 10px 0;
      border-width: 2px 2px 2px 1px;
    }

    &:hover {
      background: gray;
    }
    &.active {
      background: #000;
      color: #fff;
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;

    button {
      padding: 5px 10px;

      &:first-child {
        border-radius: 10px 10px 0 0;
        border-width: 2px 2px 1px 2px;
      }

      &:last-child {
        border-radius: 0 0 10px 10px;
        border-width: 1px 2px 2px 2px;
      }
    }
  }
}
