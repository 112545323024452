.Landing {
  .mainContainer {
    width: 100%;
    position: absolute;
    top: 100vh;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    background: #ffffff;
  }
}