.Footer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px 100px;
  padding: 25px;
  width: 100%;
  background-color: #f3f3f3;

  .icons {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 50px;

    .icon {
      height: 80px;
      width: auto;
    }
  }

  .links {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: stretch;
    text-align: center;
    gap: 20px;
    width: 100%;

    .link {
      color: #a1a1a1 !important;
      font-family: 'Nunito', Helvetica, Arial, Lucida, sans-serif;
      font-size: 18px;
      flex: 1;
    }
  }

  @media screen and (max-width: 768px) {
    .links {
      flex-direction: column;
    }
  }
}