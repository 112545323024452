.LoadingScreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  background-color: #1B1B1B;
  display: flex;
  align-items: center;
  justify-content: center;

  .loadingScreenContainer {
    animation: animateOutHeight 1s both 0.5s ease-in-out;

    .loadingScreenLogo {
      width: 16em;
      overflow: hidden;
    }
  }
}
